import React, { useState, useEffect } from 'react';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import {
  AppBar,
  Toolbar,
  Typography,
  Container,
  Card,
  CardContent,
  CardActions,
  Button,
  CircularProgress,
  Snackbar,
  Grid,
  TextField
} from '@mui/material';
import Alert from '@mui/material/Alert';
import { styled } from '@mui/system';
import StakingContract from './contracts/stakingContract.json';
import StakingTokenContract from './contracts/stakingTokenContract.json';
import { useWallet } from './useWallet.js';
import { connectWallet } from './connectWallet.js';
import logo from './img/logo.png';
import background from './img/background.jpg';
import './App.css';

const contractAddress = '0x1Fd7E157A0841BE44882C7fe75115f91878E9329';
const stakingTokenAddress = '0x5E571Ea412c78052300E5629C0944E8C97d2A69B';

const MainContainer = styled(Container)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  minHeight: '100vh',
  paddingTop: theme.spacing(4),
  paddingBottom: theme.spacing(4),
}));

const theme = createTheme({
  palette: {
    primary: { main: '#EF476F' },
    secondary: { main: '#FFD166' },
    text: {
      primary: '#FFFFFF',
      secondary: '#FFFFF',
    },
  },
  typography: {
    fontFamily: 'Poppins, Arial',
    h6: {
      color: 'rgba(255, 255, 255, 0.75)', // Set color for h6 here, adjust the rgba values as necessary
    },
    body1: {
      color: 'rgba(59, 225, 111, 1)', // Set color for body1 here, adjust the rgba values as necessary
    },
  },
});

function App() {
  const [contract, setContract] = useState(null);
  const { web3, account } = useWallet();
  // const [account, setAccount] = useState(null);
  const [participating, setParticipating] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [stakeAmount, setStakeAmount] = useState('');
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [unstaking, setUnstaking] = useState(false);
  const [claiming, setClaiming] = useState(false);
  const [unstakeAmount, setUnstakeAmount] = useState('');
  const [balance, setBalance] = useState(0);
  const [reward, setReward] = useState(0);
  const [totalStaked, setTotalStaked] = useState(0);
  const [totalStakers, setTotalStakers] = useState(0);
  const [totalRewards, setTotalRewards] = useState(0);
  const [contractBalance, setContractBalance] = useState(0);
  const [unstakedBalance, setUnstakedBalance] = useState(0);
  const [approving, setApproving] = useState(false);
  const [stakingTokenContract, setStakingTokenContract] = useState(null);



  const appStyle = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '2em',
    backgroundImage: `url(${background})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
  };

  const approveTokens = async (stakingTokenContract, account, amount, setApproving, setSnackbarMessage) => {
    try {
      setApproving(true);
      const amountInWei = web3.utils.toWei(amount, 'ether');
      await stakingTokenContract.methods.approve(contractAddress, amountInWei).send({ from: account });
      setSnackbarMessage(`Successfully approved ${parseFloat(amount).toFixed(4)} tokens!`);
    } catch (error) {
      setSnackbarMessage(`Error: ${error.message}`);
    } finally {
      setApproving(false);
    }
  };

  const stakeTokens = async (contract, account, amount, setStaking, setSnackbarMessage) => {
    try {
      setStaking(true);
      const amountInWei = web3.utils.toWei(amount, 'ether');
      await contract.methods.stake(amountInWei).send({ from: account });
      setSnackbarMessage(`Successfully staked ${parseFloat(amount).toFixed(4)} tokens!`);
    } catch (error) {
      setSnackbarMessage(`Error: ${error.message}`);
    } finally {
      setStaking(false);
    }
  };

  const unstakeTokens = async (contract, account, amount, setUnstaking, setSnackbarMessage) => {
    try {
      setUnstaking(true);
      const amountInWei = web3.utils.toWei(amount, 'ether');
      await contract.methods.unstake(amountInWei).send({ from: account });
      setSnackbarMessage(`Successfully unstaked ${parseFloat(amount).toFixed(4)} tokens!`);
    } catch (error) {
      setSnackbarMessage(`Error: ${error.message}`);
    } finally {
      setUnstaking(false);
    }
  };


  const claimRewards = async (contract, account, setClaiming, setSnackbarMessage) => {
    try {
      setClaiming(true);
      await contract.methods.claimReward().send({ from: account });
      setSnackbarMessage('Successfully claimed rewards!');
    } catch (error) {
      setSnackbarMessage(`Error: ${error.message}`);
    } finally {
      setClaiming(false);
    }
  };

  useEffect(() => {
    const getContractInstanceAndBalances = async () => {
      if (web3 && account) {
        const contractInstance = new web3.eth.Contract(StakingContract.abi, contractAddress);
        setContract(contractInstance);

        const stakingTokenInstance = new web3.eth.Contract(StakingTokenContract.abi, stakingTokenAddress);
        setStakingTokenContract(stakingTokenInstance);

        const balance = await contractInstance.methods.getStakingBalance(account).call();
        const reward = await contractInstance.methods.getRewardBalance(account).call();
        const totalStaked = await contractInstance.methods.getTotalStaked().call();
        const totalStakers = await contractInstance.methods.getTotalStakers().call();
        const totalRewards = await contractInstance.methods.totalRewards().call();
        const contractBalance = await stakingTokenInstance.methods.balanceOf(contractAddress).call();
        const unstakedBalance = await stakingTokenInstance.methods.balanceOf(account).call();



        setBalance(parseFloat(web3.utils.fromWei(balance, 'ether')).toFixed(4));
        setReward(parseFloat(web3.utils.fromWei(reward, 'ether')).toFixed(6));
        setTotalStaked(parseFloat(web3.utils.fromWei(totalStaked, 'ether')).toFixed(4));
        setTotalStakers(totalStakers);
        setTotalRewards(parseFloat(web3.utils.fromWei(totalRewards, 'ether')).toFixed(6));
        setContractBalance(parseFloat(web3.utils.fromWei(contractBalance, 'ether')).toFixed(4));
        setUnstakedBalance(parseFloat(web3.utils.fromWei(unstakedBalance, 'ether')).toFixed(4));

      }
    };


    getContractInstanceAndBalances();
    const intervalId = setInterval(getContractInstanceAndBalances, 5000); // Refresh stats every 60 seconds

    return () => clearInterval(intervalId); // cleanup function
  }, [web3, account]);


  const handleConnectWallet = async () => {
    await connectWallet();
  };

  const handleStake = async () => {
    if (contract && account && stakeAmount) {
      await stakeTokens(contract, account, stakeAmount, setParticipating, setSnackbarMessage);
      setSnackbarOpen(true);
    }
  };

  const handleUnstake = async () => {
    if (contract && account && unstakeAmount) {
      await unstakeTokens(contract, account, unstakeAmount, setUnstaking, setSnackbarMessage);
      setSnackbarOpen(true);
    }
  };

  const handleClaim = async () => {
    if (contract && account) {
      await claimRewards(contract, account, setClaiming, setSnackbarMessage);
      setSnackbarOpen(true);
    }
  };


  const handleSnackbarClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbarOpen(false);
  };

  return (
    <ThemeProvider theme={theme}>
      <link rel="stylesheet" href="https://fonts.googleapis.com/css2?family=Poppins:wght@700;800;900&display=swap"></link>
      <div className="App" style={appStyle}>
        <AppBar className="appBar">
          <Toolbar>
            <img src={logo} alt="Logo" height="50" className="logo" />
            <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
              EARNIES STAKING
            </Typography>
            {
              account ? (
                <Typography variant="body1" sx={{ marginRight: 2 }}>
                  {account}
                </Typography>
              ) : null
            }
            <Button
              variant="contained"
              color="primary"
              onClick={handleConnectWallet}
              style={{ fontSize: '12px', padding: '6px 16px' }}
            >
              {account ? 'Connected' : 'Connect Wallet'}
            </Button>
          </Toolbar>
        </AppBar>
        <MainContainer className="mainContainer">
          <Grid container justifyContent="space-around" spacing={3} className="gridContainer">
            <Grid item xs={12} sm={8} md={6} className="statGrid">
              <Card className="statCard">
                <CardContent>
                  <Typography variant="h6">Total Amount Staked</Typography>
                  <Typography variant="body1">{contractBalance}</Typography>
                </CardContent>
              </Card>
              <Card className="statCard">
                <CardContent>
                  <Typography variant="h6">Amount of Stakers</Typography>
                  <Typography variant="body1">{totalStakers}</Typography>
                </CardContent>
              </Card>
              <Card className="statCard">
                <CardContent>
                  <Typography variant="h6">Total Earnies Distributed</Typography>
                  <Typography variant="body1">{totalRewards}</Typography>
                </CardContent>
              </Card>
              <Card className="statCard">
                <CardContent>
                  <Typography variant="h6">Your Staking Balance</Typography>
                  <Typography variant="body1">{balance}</Typography>
                </CardContent>
              </Card>
              <Card className="statCard">
                <CardContent>
                  <Typography variant="h6">Your Unstaked Balance</Typography>
                  <Typography variant="body1">{unstakedBalance}</Typography>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12} sm={8} md={6} className="actionGrid">
              <Card className="actionCard">
                <CardContent>
                  <Typography variant="h5">Stake Tokens</Typography>
                  <TextField
                    value={stakeAmount}
                    onChange={e => setStakeAmount(e.target.value)}
                    label="Amount to Stake"
                    type="number"
                  />
                </CardContent>
                <CardActions>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => {
                      if (stakingTokenContract && account && stakeAmount) {
                        approveTokens(stakingTokenContract, account, stakeAmount, setApproving, setSnackbarMessage);
                        setSnackbarOpen(true);
                      }
                    }}
                    disabled={approving || !account || !stakeAmount}
                    fullWidth
                  >
                    {approving ? (
                      <CircularProgress color="inherit" size={24} />
                    ) : (
                      'Approve Tokens'
                    )}
                  </Button>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleStake}
                    disabled={participating || !account || !stakeAmount}
                    fullWidth
                  >
                    {participating ? (
                      <CircularProgress color="inherit" size={24} />
                    ) : (
                      'Stake Tokens'
                    )}
                  </Button>
                </CardActions>
              </Card>

              <Card className="actionCard">
                <CardContent>
                  <Typography variant="h5">Unstake Tokens</Typography>
                  <TextField
                    value={unstakeAmount}
                    onChange={e => setUnstakeAmount(e.target.value)}
                    label="Amount to Unstake"
                    type="number"
                  />
                </CardContent>
                <CardActions>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleUnstake}
                    disabled={unstaking || !account || !unstakeAmount}
                    fullWidth
                  >
                    {unstaking ? (
                      <CircularProgress color="inherit" size={24} />
                    ) : (
                      'Unstake Tokens'
                    )}
                  </Button>
                </CardActions>
              </Card>
              <Card className="actionCard">
                <CardContent>
                  <Card className="statCard">
                    <CardContent>
                      <Typography variant="h6">Your Staking Rewards</Typography>
                      <Typography variant="body1">{reward}</Typography>
                    </CardContent>
                  </Card>
                </CardContent>
                <CardActions>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleClaim}
                    disabled={claiming || !account}
                    fullWidth
                  >
                    {claiming ? (
                      <CircularProgress color="inherit" size={24} />
                    ) : (
                      'Claim Rewards'
                    )}
                  </Button>
                </CardActions>
              </Card>
            </Grid>
          </Grid>
        </MainContainer>
        <Snackbar
          open={snackbarOpen}
          autoHideDuration={6000}
          onClose={handleSnackbarClose}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        >
          <Alert onClose={handleSnackbarClose} severity={snackbarMessage.startsWith('Error') ? 'error' : 'success'}>
            {snackbarMessage}
          </Alert>
        </Snackbar>

      </div>
    </ThemeProvider>
  );
}

export default App;