import Web3 from 'web3';

export const connectWallet = async () => {
    console.log('Connecting wallet...');

    if (window.ethereum) {
        console.log('Ethereum provider detected.');

        try {
            // Request account access
            await window.ethereum.request({ method: 'eth_requestAccounts' });
            const web3 = new Web3(window.ethereum);
            console.log('Wallet connected.');
            return web3;
        } catch (error) {
            // User denied account access
            console.error("User denied account access");
        }
    } else if (window.web3) {
        // Legacy dApp browsers
        console.log('Legacy dApp browser detected.');
        window.web3 = new Web3(window.web3.currentProvider);
        return window.web3;
    } else {
        // Non-dApp browsers...
        console.error('Non-Ethereum browser detected. You should consider trying MetaMask!');
    }
};
