import { useState, useEffect } from 'react';
import { connectWallet } from './connectWallet.js';

export function useWallet() {
    const [web3, setWeb3] = useState(null);
    const [account, setAccount] = useState(null);
    const [connecting, setConnecting] = useState(false);

    useEffect(() => {
        async function connect() {
            if (!connecting) {
                setConnecting(true);
                const web3Instance = await connectWallet();
                setWeb3(web3Instance);
                setConnecting(false);

                if (web3Instance) {
                    const accounts = await web3Instance.eth.getAccounts();
                    setAccount(accounts[0]);
                }
            }
        }

        if (!web3) {
            connect();
        }

        // listen for account changes
        if (window.ethereum) {
            window.ethereum.on('accountsChanged', async function (accounts) {
                setAccount(accounts[0]);  // update the account state
            });

            // cleanup function
            return () => {
                window.ethereum.removeAllListeners('accountsChanged');
            };
        }
    }, [web3, connecting]);

    return { web3, account };
}
